import React from 'react';

import Stack, {
  onEntryChange,
} from '@shared/contentstack/live-preview/live-preview';
import { StandardPromo } from '@src/cms-components';
import { useCallback, useEffect, useState } from 'react';
import { LivePreviewSelect } from '@shared/contentstack/live-preview/widgets/live-preview-widgets';
import { LivePreviewHeader } from '@shared/contentstack/live-preview/widgets/live-preview-widgets.styles';
import { standardPromoMapper } from '@cms-components/promos/standard-promo/mappers/standard-promo.mapper';

const color_options = [
  { label: 'Light', value: 'light' },
  { label: 'Mid', value: 'mid' },
];

const StandardPromoPreviewPage = (props: any) => {
  const { pageContext } = props;
  const [bgColor, setBgColor] = useState<any>('light');
  const [livePreviewData, setLivePreviewData] = useState<any>({});

  Stack.startLivePreview(true);

  const fetchData = useCallback(async () => {
    const response = (await Stack.getEntry({
      contentTypeUid: 'standard_promo',
      url:
        pageContext?.url && pageContext?.url !== '/'
          ? pageContext?.url
          : '/live-preview',
    })) as any;

    setLivePreviewData(response[0][0]);
  }, [pageContext]);

  useEffect(() => {
    onEntryChange(() => fetchData());
  }, [fetchData]);

  return (
    <>
      <LivePreviewHeader>
        <LivePreviewSelect
          title="Background Color"
          options={color_options}
          setSelectedItem={setBgColor}
        />
      </LivePreviewHeader>

      {!!livePreviewData && (
        <StandardPromo
          {...standardPromoMapper(livePreviewData, {
            backgroundColor: bgColor,
            button: false,
            variant: 'full-width',
          })}
        />
      )}
    </>
  );
};

export default StandardPromoPreviewPage;
